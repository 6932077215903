.mainSection {
    display: flex;
    width: 80vw;
    margin: 0 auto;
    flex-direction: column;
    padding-bottom: 10vh;
}

.title {
    color: #737373;
    margin: 3vh auto 3vh 0;
    cursor: default;
}

.cardsList {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to new lines */
    list-style: none;
    gap: 6vh;
    width: 60vw;
    margin: 0 auto;
    justify-content: center; /* Centers the cards */
}

.card {
    width: 16vw;
    border: 1px solid #737373;
    padding: 1vw;
    text-align: center;
    border-radius: 2vh;
    min-width: 220px; /* Ensures the cards don't get too small */
    box-sizing: border-box;
    cursor: default;
}

.card h3, .card h2 {
    color: #737373;
    margin: 2vh auto 0 auto;
}

.card img {
    height: 10vh;
    margin: 3vh auto 1vh auto;
}

.card ul {
    list-style: none;
    text-align: left;
    padding: 1vw;
}

.card ul li {
    margin-bottom: 10px;
}

