.navbar {
  background-color: transparent;
  height: 10vh;
  margin-top: -3vh;
  padding-top: 3vh;
  position: fixed;
  z-index: 999;
  width: 100vw;
}

.navbar ul {
    display: flex;
    justify-content: flex-end;
    margin-right: 1vw;
    align-items: center;
}

.navbar ul li {
    margin-right: 2vw;
    list-style: none;
}

.navbar ul li a {
    text-decoration: none;
    color: #313131;
}


.languageContainer {
    display: flex;
    align-items: center;
 
  }

  .languageContainer img {
    height: 2.6vh;
    width: 2.6vh;
    margin-right: 0.6vw;
    margin-top: 0.1vh;
  }
  
.languageDropdown option {
  cursor: pointer;
}

  .languageDropdown select {
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }