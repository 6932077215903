body {
  margin: 0;
  font-family: 'Albert Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list-icon {
  width: 1.2vw;
  height: auto;
  margin-right: 0.8vw;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;

}
::-webkit-scrollbar-track {
  background-color: rgb(92, 92, 92);
  border-radius: 10px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background: rgb(175, 175, 175);
  border-radius: 10px;
  cursor: pointer;
 
}
