.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: linear-gradient(to bottom, #ffffff67, #ffffff9a);
  color: #313131;
  border-bottom-left-radius: 6vw;
  border-bottom-right-radius: 6vw;
  text-align: center;
  padding-top: 12vh;
  cursor: default;
}

.hero-section-left {
  display: flex;
  flex-direction: column;
  margin-top: 4vh;
  width: 45vw;
}

.hero-section-description h2 {
  margin-bottom: 0;
}

.hero-section-left p {
  font-size: 2vh;
}

.image-section {
  margin-left: 15vw;
  width: 25vw;
}

.profile-image {
  height: 18vh;
  width: 18vh;
  border-radius: 50%;
}

.hello-image {
  height: 3vh;
  margin-top: -1vh;
}



.hero-text {
  display: flex;
  align-items: center; 
  background: linear-gradient(to right, #6e6e6e, #b6b6b6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 4vh;
  font-weight: 900;
  font-size: 60px;
  margin-bottom: 10vh;
}

.profile-description {
  font-size: 3vh;
  font-weight: 600;
  color: #313131;
  margin-top: 2vh;
}

.profile-subtext {
  font-size: 2.8vh;
  width: 80vw;
  display: flex;
  margin: 4vh auto 6vh auto;
  justify-content: center;
  text-align: center;
  cursor: default;
  color:#313131;
font-size: 2.2vh;
}
