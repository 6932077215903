.mainContainer {
  width: 100vw;
font-size: 2.4vh;
background-color: #eeeeee;
color: #313131;
}

.projectDisplay {
    max-width: 80vw;
    margin: 0 auto;
    padding: 20px;
    color: #313131;
  }
  
  .projectSection {
    margin-bottom: 12vh;
  }

 
  .sectionTitle{
    margin-bottom: 8vh;
  }
  
  .projectTitle {
    font-size: 2rem;
    color: #313131; 
    margin-bottom: 6vh;
    font-weight: 500;
  }
  
  .projectOverview p,
  .projectSection p {
    font-size: 2.4vh;
    line-height: 1.6;
    color: #313131;
  }
 
  
  .projectDisplay h2 {
    font-size: 2rem;
    color: #313131;
    margin-bottom: 10px;
  }
  
  .projectDisplay h3 {
    font-size: 1.5rem;
    color: #313131;
    margin-bottom: 10px;
  }
  
  .projectDisplay ul {
    list-style: none; 
    margin-left: 0;
    padding-left: 0;
  }
  
  .projectDisplay ul li {
    margin-bottom: 8px;
    color: #313131;
  }


  .imageDisplay{
    width: 60vw;
    display: flex;
    margin: 6vh auto;
  }

  .heroImage {
    width: 80vw;
  }


  .findingsList {
    display: flex;
    flex-direction: column;
    margin-top: 6vh;
    margin-left: 0; 
    gap: 4vh
  }
  
  .finding {
    display: flex;
    align-items: center;
    width: 60vw;
    margin: 0 auto;
    background-color: #f3f3f3;
    padding: 10px 20px;
    border-radius: 8px;
  }
  
    .findingIcon{
      width: 10vh;
      height: auto;
      margin-right: 2vw;
    }

  .goalsList {
      display: flex;
      flex-direction: column;
      margin-top: 3vh;
    }

    .goal svg {
      margin-right: 1vw;
      margin-left: 0;
    }
  
  .carousel {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; /* Center everything inside */
    position: relative;
    width: 80vw; 
    max-width: 600px;
    margin: 0 auto; /* Center the carousel */
  }
  .singleImageDisplay {
    height: 50vh;
    width: auto;
    display: flex;
    margin: 6vh auto;
  }

.flowContainer {
  height: 100vh;
}

  /* Hide all images by default */
  .imageDisplay {
    display: none; /* Hide all images by default */
    max-width: 80vw; 
    border-radius: 10px; 
    margin: 0 auto; /* Ensure images are centered */
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  /* Display only the active image */
  .imageDisplay.active {
    display: block;
    opacity: 1;
  }
  
  /* Previous and Next button styling */

  .buttonContainer { 
    display: flex;
    justify-content: space-between;
    width: 80vw;
    position:absolute;
    z-index: 999
  }
  .carouselButton {
    background-color: #313131;
    border: none;
    color: #313131;
    padding: 1vh;
    font-size: 3vh; 
    cursor: pointer; 
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carouselButton svg {
    margin: 0;
    padding: 0;
  }
 
  
  .carouselButton:disabled {
    background-color: #b3b3b3;
    cursor: default;
    color: #777777;
  }
   

  /* Adding animations for the slide transitions */
  .slide-enter {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  
  .slide-exit {
    transform: translateX(0);
    opacity: 1;
  }
  
  .slide-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
  }

  .imageDescription {
    display: flex;
    align-items: center;
  }
  .personaCards {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column; 
  }
  
  .card {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    background: linear-gradient(to bottom, #ffffff86, #e2e2e267);
    border-radius: 12px;
    position: relative;
    transition: transform 0.3s ease;
    width: 60vw;
    margin: 4vh auto;
    padding: 1vh 1vw;
  }


  .leftSection {
    width: 26vw;
    align-items: center;
    margin-right: 2rem;
    height: 100%;
    justify-content: flex-end;
  }
  
  .personaAvatar {
    height: 100%;
    width: auto;
    object-fit: cover;
    margin-right: 1rem;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  
  .personaBasicInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10vw;
    z-index: 999
  }
  
  .personaName {
    font-size: 1.5rem; 
    margin: 0 0 0.5rem 0;
    z-index: 999
  }
  
  .personaAge {
    font-size: 1.5rem; 
    margin: 0 0 0.5rem 0;
    z-index: 999
  }
  
  .rightSection {
    display: flex;
    flex-direction: column;
    z-index: 999;
    padding: 1vh 1vw
  }
   
  
  .rightSection p strong {
    color: #313131;
  }
  
  @media (max-width: 768px) {
    .card {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .leftSection {
      margin-bottom: 1rem;
      width: 40vw;
    }
  
    .rightSection {
      text-align: left;
    }
  }

  .mainInfo {
    margin: 1vh 0;
  }

  .mainInfo p {
margin: 0;
  }

  .rows {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    margin: 2vh 0;
  }

  .infoRow {
    display: flex;
    gap: 1vw;
    margin-bottom: 0
  }

  .innerCard {
    width: 18vw;
    height: flex 1;
    border: 1px solid rgb(75, 75, 75);
    border-radius: 8px;
    padding: 10px;
   background-color: #eeeeee91;
  }

  .innerCard p {
    margin: 0;
    color: rgb(80, 80, 80);
  }

  .innerCard strong {
    color: #313131;
  }