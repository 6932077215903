.projectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  padding: 20px;
  margin: 0 auto;
}

.projectsCards {
  display: flex;
  flex-direction: column;
  gap: 6vh;
  width: 100%;
  margin-top: 8vh;
}

.projectCard {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 6vh 4vh;
  gap: 2vw;
  border-radius: 2vh;
  background: linear-gradient(to bottom, #ffffff67, #ffffff9a);
  position: relative;
  cursor: pointer;
}

.leftImage {
  flex-direction: row; /* Image left, text right */
}

.rightImage {
  flex-direction: row-reverse; /* Image right, text left */
}

.imageContainer {
  position: relative;
  width: 50%;
  height: auto;
}

.cardImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.411);
  backdrop-filter: blur(4px); /* Apply blur to the background */
  opacity: 0;
  transition: opacity 0.4s ease;
  border-radius: 10px;
}

.projectCard:hover .overlay {
  opacity: 1;
}

.projectLogo {
  max-width: 50%;
  max-height: 50%;
  z-index: 2;
}

.cardText {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #313131; /* Dark text */
}

.cardTitle {
  font-size: 1.8rem;
  margin: 1vh 0;  
  display: flex;
  align-items: center;
  gap: 10px;
  color: #313131; /* Dark title text */
}

.cardIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4vh;
  top: 2vh;
  z-index: 999;
}

.projectsContainer p {
  color: #313131; /* Dark text for paragraphs */
}

.projectsContainer button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #333;
}

.tagsList {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  color: #818181;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.tagItem {
  margin-bottom: 5px;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0 4px;
}

.locked {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2vh;
  justify-content: center;
}

.locked label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.locked svg {
  margin-right: 6px;
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-4px); }
  40% { transform: translateX(4px); }
  60% { transform: translateX(-4px); }
  80% { transform: translateX(4px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s ease;
}